import React, { Component } from 'react';
import { Link } from 'gatsby';
import DrugAutoComplete from '../../components/DrugAutoComplete';
import ActiveDrugs from '../../components/ActiveDrugs';
import { Grid, FormHelperText } from '@material-ui/core';
import Layout from '../../components/Layout';
import allSvc from '../../services/allServices';
import StepComponent from '../../components/StepComponent';
import '../../assets/css/covermore.scss';
import CovermoreArrow from '../../components/CovermoreArrow';
import CountrySearch from '../../components/CountrySearch';
import FooterLogo from '../../components/FooterLogo';
import DocModal from '../../components/DocModal/DocModal';
import Bottle from '../../components/Images/Bottle';
import Carton from '../../components/Images/Carton';
import useConfig from '../../helpers/hooks/useConfig';

export default class MainPage extends Component {
  constructor() {
    super();

    this.state = {
      gotDrugs: false,
      drugList: [],
      activeDrugsList: [],
      locationId: '',
      destinationId: '',
      email: '',
      terms_accept: false,
      further_information_accept: false,
      unmatchedDrugs: false,
      errorModal: false,
      successModal: false,
      image1Modal: false,
      image2Modal: false,
      reportErrorModal: false,
      defaultLocationId: 'GBR',
      countries: [{ name: 'Loading Countries...', id: 0 }],
    };

    this.getDrugs = getDrugs.bind(this);
    this.getCountries = getCountries.bind(this);
    this.updateDrugList = updateDrugList.bind(this);
    this.setDefaultLocation = setDefaultLocation.bind(this);
    this.updateLocation = updateLocation.bind(this);
    this.updateDestinations = updateDestinations.bind(this);
    this.deleteDrug = deleteDrug.bind(this);
    this.checkUnmatched = checkUnmatched.bind(this);
    this.validForm = validForm.bind(this);
    this.submitForm = submitForm.bind(this);
    this.modalClose = modalClose.bind(this);
    this.createReport = createReport.bind(this);

    async function getDrugs(jsonAPI) {
      if (this.state.gotDrugs !== true) {
        // Async get drugs
        allSvc
          .autocomplete(jsonAPI)
          .then(response => {
            if (response.success && response.data) {
              this.setState({ gotDrugs: true, drugList: response.data.items });
            } else {
              this.setState({ gotDrugs: false });
            }
          })
          .catch(err => {
            this.setState({ gotDrugs: false });
          });
      }
    }
    async function getCountries(jsonAPI) {
      allSvc
        .getCountries(jsonAPI)
        .then(resp => {
          if (resp.success && resp.data) {
            this.setState({ countries: resp.data.countries });
          }
        })
        .catch(err => {
          console.log(err?.message || err);
        });
    }
    function updateDrugList(item) {
      if (item && item.match === false && item.name && item.value) {
        item.name = item.value;
      }
      if (item && item.name) {
        // Only update the state with the new drug if the drug is either valid or a non match
        this.setState(
          prevState => ({
            activeDrugsList: [...prevState.activeDrugsList, item],
          }),
          () => {
            this.checkUnmatched();
          }
        );
      }
    }
    function updateLocation(locationId) {
      this.setState({ locationId });
    }
    function setDefaultLocation() {
      if (this.state.defaultLocationId) {
        this.setState({ locationId: this.state.defaultLocationId });
      }
    }
    function updateDestinations(destinationId) {
      this.setState({ destinationId });
    }
    function deleteDrug(name) {
      const newDrugList = this.state.activeDrugsList.filter(
        drug => drug && drug.name && drug.name.toLowerCase() !== name.toLowerCase()
      );
      this.setState({ activeDrugsList: newDrugList }, () => {
        this.checkUnmatched();
      });
    }
    function checkUnmatched() {
      this.state.activeDrugsList.filter(drug => {
        return drug.match === false ? true : false;
      }).length > 0
        ? this.setState({ unmatchedDrugs: true })
        : this.setState({ unmatchedDrugs: false });
    }
    function validForm(data) {
      const errors = [];
      if (!data.locationId.length) {
        errors.push('Select a location');
      }
      if (!data.destinationId.length) {
        errors.push('Select your destination');
      }
      if (!data.activeDrugsList.length) {
        errors.push('Enter active ingredients');
      }
      if (!errors.length) {
        return true;
      } else {
        return errors;
      }
    }
    function validEmail(email) {
      const pattern = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/);
      return pattern.test(email.toLowerCase());
    }
    // eslint-disable-next-line no-unused-vars
    function emailValidation(email) {
      if (email && email.length >= 4 && email.includes('@')) {
        return validEmail(email);
      } else {
        // Do not show error until user makes error
        return true;
      }
    }
    function submitForm() {
      const isValid = validForm(this.state);
      if (isValid && isValid === true) {
        this.createReport(
          this.state.config.activeGateway,
          this.state.activeDrugsList,
          this.state.locationId,
          this.state.destinationId,
          this.state.unmatchedDrugs
        );
      } else {
        //messy implementation
        this.setState({ errors: isValid, errorModal: true });
      }
    }
    function modalClose(modalName) {
      if (modalName === 'successModal') this.setState({ successModal: false });
      if (modalName === 'errorModal') this.setState({ errorModal: false });
      if (modalName === 'reportErrorModal') this.setState({ reportErrorModal: false });
    }
    function createReport(baseUrl, drugsList, locationId, destinationId, unmatchedDrugs, further_information_accept) {
      const matched_drugs = [];
      const unmatched_drugs = [];
      drugsList.forEach(drug => {
        if (drug.id && drug.name && typeof drug.match === 'undefined') {
          matched_drugs.push({ drug_id: drug.id });
        }
      });
      if (unmatchedDrugs !== false) {
        drugsList.forEach(drug => {
          if (drug && drug.name && drug.match === false) {
            unmatched_drugs.push({ drug: drug.name });
          }
        });
      }
      const dataObj = {
        from_country_id: locationId,
        to_country_id: destinationId,
        matched_drugs,
        unmatched_drugs,
        user_id: 'unauthorized user',
        email: this.state.email,
        marketing_accept: +further_information_accept,
      };

      allSvc
        .createReport(baseUrl, dataObj)
        .then(resp => {
          if (resp.success && resp.reportId) {
            this.setState({
              successModal: true,
              modelOpen: false,
              reportId: resp.reportId,
            });
          } else {
            this.setState({ reportErrorModal: true });
            throw new Error('Error sending report.');
          }
        })
        .catch(err => {
          this.setState({ reportErrorModal: true });
        });
    }
  }
  componentWillMount() {
    // When component is due to mount, fetch drug data
    const { config } = useConfig();
    this.getDrugs(config?.activeGateway);
    this.getCountries(config?.activeGateway);
    this.setDefaultLocation();

    this.setState({ config });

  }
  render() {
    return (
      <Layout covermore={true}>
        <Grid
          container
          spacing={2}
          style={{
            margin: 'auto',
            maxWidth: '100%',
            paddingTop: '10px',
            paddingBottom: '10px',
          }}
          className='covermore'
        >
          <StepComponent stepNumber='1' stepMessage='Where are you travelling from?'>
            <CountrySearch
              id='location-select'
              label='Origin'
              countries={this.state.countries}
              updateLocation={this.updateLocation}
              defaultLocationId={this.state.defaultLocationId}
            />
          </StepComponent>
          <StepComponent stepNumber='2' stepMessage='Where are you travelling to?'>
            <CountrySearch
              id='destination-search'
              label='Destination'
              countries={this.state.countries}
              updateLocation={this.updateDestinations}
              defaultLocationId=''
            />
            <FormHelperText style={{ fontSize: '12px', maxWidth: '90%' }}>
							Check all destinations where you are walking through security with your medication.
            </FormHelperText>
          </StepComponent>
          <StepComponent
            stepNumber='3'
            stepMessage='What are the active ingredients in your medicine?'
          >
            <DrugAutoComplete drugList={this.state.drugList} updateDrugList={this.updateDrugList} />
          </StepComponent>
          <Grid item xs={12} sm={12} md={6}>
            <ActiveDrugs
              activeDrugsList={this.state.activeDrugsList}
              deleteDrug={this.deleteDrug}
              covermore
            />
            {this.state.unmatchedDrugs === true && (
              <FormHelperText style={{ fontSize: '12px', color: 'var(--red)' }}>
                <strong>Note: </strong> your unmatched drug was not found in our database. <br/>Please recheck the drug name for spelling mistakes.
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <FormHelperText style={{ fontSize: '12px', paddingBottom: '10px' }}>
							Where to find the active ingredient of your medicine
            </FormHelperText>
            <Grid
              container
              direction='row'
              alignItems='stretch'
              style={{ maxWidth: '90%' }}
              className='image-grid'
            >
              <div
                className='image-container'
                style={{ marginRight: '5px' }}
                onClick={() => this.setState({ image1Modal: true })}
              >
                <Bottle wrapStyle={{ maxWidth: '90%', minWidth: '150px' }} />
                <div className='middle'>
                  <div className='text'>Click here to expand</div>
                </div>
              </div>
              <div
                className='image-container'
                style={{ marginRight: '5px' }}
                onClick={() => this.setState({ image2Modal: true })}
              >
                <Carton wrapStyle={{ maxWidth: '90%', minWidth: '150px' }} />
                <div className='middle'>
                  <div className='text'>Click here to expand</div>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12} className='covermore'>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
              }}
            >
              <button
                className='btn-swu'
                onClick={() => {
                  this.submitForm();
                }}
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  color: 'white',
                  display: 'flex',
                }}
              >
								Submit
              </button>
              <FooterLogo />
            </div>
          </Grid>
        </Grid>
        <DocModal
          isVisible={this.state.errorModal}
          onClose={() => this.setState({ errorModal: false })}
          title='Please correct the following:'
          content={<ErrorModal errors={this.state.errors} />}
        />
        <DocModal
          isVisible={this.state.successModal}
          onClose={() => this.setState({ successModal: false })}
          title='Report created successfully.'
          content={
            <>
              <p>
								Thank you for using DocHQ Travel Medicine Restriction Checker.<br />
								A copy of your report has also been sent to {this.state.email}.<br />
								Please check also your spam folder.
              </p>
              <SuccessModal email={this.state.email} reportId={this.state.reportId} />
            </>
          }
        />
        <DocModal
          isVisible={this.state.reportErrorModal}
          title='An error occurred sending the report, please try again'
          hideBody
          onClose={() => this.setState({ reportErrorModal: false })}
        />
        <DocModal
          isVisible={this.state.image1Modal}
          onClose={() => this.setState({ image1Modal: false })}
          title='Where to find the active ingredient of your medicine'
          content={<Bottle wrapStyle={{ maxHeight: '60vh' }} imgStyle={{ width: 'auto', margin: 'auto', right: '0' }} />}
        />
        <DocModal
          isVisible={this.state.image2Modal}
          onClose={() => this.setState({ image2Modal: false })}
          title='Where to find the active ingredient of your medicine'
          content={<Carton wrapStyle={{ maxHeight: '60vh' }} imgStyle={{ width: 'auto', margin: 'auto', right: '0' }} />}
        />
      </Layout>
    );
  }
}
const ErrorModal = props => (
  <React.Fragment>
    <ul>
      {props.errors.map((error, i) => {
        return <li key={i}>{error}</li>;
      })}
    </ul>
  </React.Fragment>
);

const SuccessModal = props => {
  return (
    <React.Fragment>
      <Link to={`/covermore/report?reportId=${props.reportId}`} className='covermore'>
        <button
          className='btn-swu covermore'
          style={{
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            color: 'white',
            display: 'flex',
            textDecoration: 'none',
          }}
        >
          <span>View Report</span>
          <CovermoreArrow style={{ fill: 'white' }} />
        </button>
      </Link>
    </React.Fragment>
  );
};
