import React from 'react';
const logo = require('../assets/powered-by-dochq.png');
const FooterLogo = () => (
  <React.Fragment>
    <div className='dochq-logo'>
      <img src={logo} alt='Powered By DocHQ' style={{ maxHeight: '50px' }} />
    </div>
  </React.Fragment>
);

export default FooterLogo;
